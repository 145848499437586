/* global hbspt */
import React, { useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import { hubspotPortalId } from "../utils/analytics";
import { jsx } from "@emotion/core";

const FormContainer = styled.div<any>`
  width: 100%;

  iframe {
    width: 100% !important;
    ${props => props.newsletter ? "height: 40px !important;" : ""}
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.md}px) {
    width: ${props => props.newsletter ? "40%" : "100%"};
  }
`;

// Injected styles into hubspot form iframe, all of this is really dumb and stems
// from the fact that hubspot doesn't allow follow-up emails on form submissions that
// don't use captcha and their own Form Submission API doesn't support Captcha, so it's
// a bit of a catch 22
// Read more: https://community.hubspot.com/t5/APIs-Integrations/Forms-Api-v3-Captcha-Handling/td-p/323034
const styles = `
  label,
  .hs-recaptcha,
  .hs_error_rollup,
  .hs-error-msgs {
    display: none !important;
  }

  .actions {
    margin: 0;
    padding: 0;
  }

  .hs-submit {
    width: 90px;
    display: block;
    margin-left: 10px;
  }

  .hs-submit .actions .hs-button {
    margin: 0;
    padding: 0 20px;
    border: none;
    position: relative;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #1E3961;
    transition: color .2s ease-in-out, background .2s ease-in-out;
    cursor: pointer;
    background: #fff;
    height: 40px;
    width: 100%;
  }

  .hs-submit .actions .hs-button:hover,
  .hs-submit .actions .hs-button:active:not(.inactive):not(.link) {
    color: #fff;
    background: #1E3961;
    border-color: #1E3961;
    font-size: 14px;
    line-height: 14px;
  }

  form {
    display: inline-flex;
    justify-content: flex-end;
    width: 100% !important;
  }

  .hs-email {
    width: 48%;
    padding-left:10px;
  }

  .hs_firstname {
    width: 30%;
    padding-left:10px;
  }

  .hs-email input, .hs-firstname input {
    border: none;
    font-family: 'Lato', sans-serif;
    color: #1E3961;
    padding: 10px 7px 10px 23px;
  }
`;

export interface IHubspotFormProps {
  formId: string,
  onFormSubmitted: Function,
  newsletter: boolean,
}

export default function HubspotForm(props: IHubspotFormProps): JSX.Element {

  const {
    formId,
    onFormSubmitted,
    newsletter,
  } = props;

  let formElement;

  const callback = event => {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
      // inject styles into hubspot iframe
      const iframe = formElement.querySelector('iframe');
      const head = iframe.contentDocument.head;
      const style = document.createElement("style");
      style.append(document.createTextNode(styles));
      head.append(style);
    }
    // handle submitted
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
      onFormSubmitted();
    }
  }

  useEffect((node) => {
    if (node === null) return;

    if (newsletter) {
      // Setup listeners for hubspot form events
      window.addEventListener('message', callback);
    }

    // segment loads hubspot asyncronously, so we need to wait until it's ready to create the form
    analytics.ready(function () {

      // Set up the hubspot form
      hbspt.forms.create({
        portalId: hubspotPortalId,
        formId: formId,
        target: `#form_${formId}`,
      });
    })

    if (newsletter) {
      return () => window.removeEventListener('message', callback);
    }
  }, [formElement]);

  return (
    <FormContainer newsletter={newsletter} id={`form_${formId}`} ref={el => formElement = el}></FormContainer>
  )
}