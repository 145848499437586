import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";

import BoxLink from "./boxLink";
import { ITheme } from "../utils/types";
import { md } from "../utils/breakpoints";

const CTAContainer = styled.div<any>`
  text-align: center;
  max-width: 1035px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ContactContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 73px;
  justify-content: center;
  align-items: center;
`;

const ContactCTA = styled.div<any>`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 44px;
  line-height: 48px;
  color: #fff;
  margin: 20px 60px 20px 20px;
  height: 48px;

  @media (max-width: ${md}px) {
    font-size: 30px;
    height: 30px;
    margin: 0 0 20px 0;
    text-align: center;
  }
`;

const HeaderCTA = styled.div<any>`
  display: block;
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 33px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  margin: 0 auto 20px;
  padding-top: 73px;
  max-width: 958px;

  @media (max-width: ${md}px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const ContactButtonContainer = styled.div<any>`
  margin: 20px;
`;

export interface IFooterCtaProps {
  title: string,
  callout: string,
  buttonText?: string,
  buttonLink?: string,
}

export default function FooterCta(props: IFooterCtaProps): JSX.Element {
  const options = {
    buttonText: "Contact Us",
    buttonLink: "/contact",
    ...props,
  };

  const theme = useTheme<ITheme>();

  return (
    <CTAContainer>
      <HeaderCTA>{options.title}</HeaderCTA>
      <ContactContainer>
        <ContactCTA>
          {options.callout}
        </ContactCTA>
        <ContactButtonContainer>
          <BoxLink big to="/contact" textColor={theme.primaryColor} borderColor={theme.primaryColor}>
            {options.buttonText}
          </BoxLink>
        </ContactButtonContainer>
      </ContactContainer>
    </CTAContainer>
  );
}