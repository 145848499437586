import { ITheme } from "./types";

export const theme: ITheme = {
    primaryColor: "#1E3961",
    secondaryColor: "#12A481",
    backgroundColor: "#F9F4F1",
    borderColor: "#B59D8E",
    offPrimaryColor: "#6B7F92",
    contentFontFamily: "Lato, sans-serif",
    headerFontFamily: "Lora, serif",
    md: 768,
    lg: 992,
};