import React from "react";
import styled from "@emotion/styled";
import {Link} from "gatsby";

import Container from "../../container";
import NavList from "./navList";

export interface ITopNavProps {
    logoURL: string;
    servicesLinkTitle: string;
    caseStudiesLinkTitle: string;
    contactLinkTitle: string;
    teamLinkTitle: string;
    backgroundColor: string;
}

const TopBarContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 0;
`;

const LogoLink = styled(Link)`
    display: block;
    height: 50px;
`;


export default (props: ITopNavProps) => {
    const {
        logoURL,
        servicesLinkTitle,
        caseStudiesLinkTitle,
        teamLinkTitle,
        contactLinkTitle,
        backgroundColor,
    } = props;

    return (
        <header style={{
            backgroundColor,
        }}>
            <Container>
                <TopBarContent>
                    <LogoLink to="/">
                        <img alt="Strategica Logo" src={logoURL}/>
                    </LogoLink>
                    <NavList 
                        servicesLinkTitle={servicesLinkTitle}
                        caseStudiesLinkTitle={caseStudiesLinkTitle}
                        teamLinkTitle={teamLinkTitle}
                        contactLinkTitle={contactLinkTitle} />
                </TopBarContent>
            </Container>
        </header>
    );
};