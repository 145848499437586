import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import {Link} from "gatsby";

import Container from "../container";
import FooterCta from '../FooterCta';
import BoxLink from "../boxLink";
import {ITheme} from "../../utils/types";
import {md} from "../../utils/breakpoints";
import EmailSignup from '../contact/EmailSignup';

const FooterContainer = styled.footer<any>`
    border-top: 1px solid ${props => props.theme.secondaryColor};
    padding-top: 10px;
    flex-shrink: 0;
`;

const FooterContent = styled.div<any>`
    background: ${props => props.theme.secondaryColor};
`;

const ContactContainer = styled.div<any>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 73px;
    justify-content: center;
    align-items: center;
`;

const ContactCTA = styled.div<any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 44px;
    line-height: 48px;
    color: #fff;
    margin: 20px 60px 20px 20px;
    height: 48px;

    @media (max-width: ${md}px) {
        font-size: 30px;
        height: 30px;
        margin: 0 0 20px 0;
        text-align: center;
    }
`;

const HeaderCTA = styled.div<any>`
    display: block;
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 33px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    margin: 0 auto 20px;
    padding-top: 73px;
    max-width: 843px;

    @media (max-width: ${md}px) {
        font-size: 20px;
        line-height: 28px;
    }
`

const ContactButtonContainer = styled.div<any>`
    margin: 20px;
`;

const FooterNavContainer = styled.div<any>`
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 20px 0;
`;

const FooterNavContent = styled.div<any>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${md}px) {
        justify-content: center;
    }
`;

const Copyright = styled.div<any>`
    color: #fff;
    font-size: 14px;
`;

const FooterLinks = styled.nav<any>`
    @media (max-width: ${md}px) {
        display: none;
    }
`;

const FooterLinkList = styled.ul<any>`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const FooterLinkItem = styled.li<any>`
    display: inline-block;
    margin: 10px;

    &:first-child {
        margin-left: 0;
    }
`;

const FooterNavLink = styled(Link)<any>`
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    color: #fff;
    transition: border-bottom-color .2s ease-in-out;

    &:hover {
        border-bottom-color: #fff;
    }
`;

export interface IFooterProps {
    footerContactCTA: string;
    footerContactButtonText: string;
    servicesLinkTitle: string;
    caseStudiesLinkTitle: string;
    contactLinkTitle: string;
    teamLinkTitle: string;
    hideContactBanner?: boolean;
    FooterCTA?: Function;
}

export default function Footer(props: IFooterProps): JSX.Element {
    const {
        footerContactButtonText,
        footerContactCTA,
        servicesLinkTitle,
        caseStudiesLinkTitle,
        contactLinkTitle,
        teamLinkTitle,
        hideContactBanner,
        FooterCTA,
    } = props;

    return (
        <FooterContainer>
            <FooterContent>
                {!hideContactBanner && (
                    <FooterCta
                        title="We create high growth go-to-market success through our Norm Breaking™ Model."
                        callout={footerContactCTA}
                        buttonLink="/contact"
                        buttonText={footerContactButtonText}
                    />
                )}
                {FooterCTA && <FooterCTA />}
            </FooterContent>
            <FooterContent style={{ marginTop: "12px" }}>
                <EmailSignup />
                <FooterNavContainer>
                    <Container>
                        <FooterNavContent>
                            <FooterLinks>
                                <FooterLinkList>
                                    <FooterLinkItem>
                                        <FooterNavLink to="/services">
                                            {servicesLinkTitle}
                                        </FooterNavLink>
                                    </FooterLinkItem>
                                    <FooterLinkItem>
                                        <FooterNavLink to="/case-studies">
                                            {caseStudiesLinkTitle}
                                        </FooterNavLink>
                                    </FooterLinkItem>
                                    <FooterLinkItem>
                                        <FooterNavLink to="/team">
                                            {teamLinkTitle}
                                        </FooterNavLink>
                                    </FooterLinkItem>
                                    <FooterLinkItem>
                                        <FooterNavLink to="/contact">
                                            {contactLinkTitle}
                                        </FooterNavLink>
                                    </FooterLinkItem>
                                    <FooterLinkItem>
                                        <FooterNavLink to="/blog">
                                            Resources
                                        </FooterNavLink>
                                    </FooterLinkItem>
                                </FooterLinkList>
                            </FooterLinks>
                            <Copyright>
                                © Strategica Partners {new Date().getFullYear()}
                            </Copyright>
                        </FooterNavContent>
                    </Container>
                </FooterNavContainer>
            </FooterContent>
        </FooterContainer>
    );
}