import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "emotion-theming";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";

import { IService } from "../../utils/types";
import { theme } from "../../utils/theme";
import Head from "./head";
import TopNav from "./topNav/topNav";
import Footer from "./footer";

const PageContainer = styled.div<any>`
  height: 100%;
  flex-direction: column;
  display: flex;
`;

const PageContent = styled.div`
  flex: 1 0 auto;
`;

export interface ILayoutProps {
  title: string;
  children: any;
  hideContactFooterBanner?: boolean;
  FooterCTA?: Function;
  landingPage?: boolean;
}

export default (props: ILayoutProps) => {
  const data = useStaticQuery(graphql`
    query {
      contentfulLayout {
        contactLinkTitle
        servicesLinkTitle
        teamLinkTitle
        caseStudiesLinkTitle
        headerLogo {
          file {
            url
          }
        }
        sitePreviewImage {
          file {
            url
          }
        }
        footerContactCTA
        footerContactButtonText
        siteTitle
        siteDescription
        siteAuthor
      }
    }
  `);

  const {
    contactLinkTitle,
    servicesLinkTitle,
    teamLinkTitle,
    caseStudiesLinkTitle,
    headerLogo,
    footerContactCTA,
    footerContactButtonText,
    siteDescription,
    siteTitle,
    siteAuthor,
    sitePreviewImage
  } = data.contentfulLayout;

  const {
    hideContactFooterBanner,
    title,
    FooterCTA,
    children,
    landingPage,
  } = props;

  const globalStyles = css`
    * {
      box-style: border-box;
    }

    body {
      background-color: ${landingPage ? "#fff" : theme.backgroundColor};
      color: ${theme.primaryColor};
    }

    html, body, #___gatsby, #gatsby-focus-wrapper {
      height: 100%;
    }
  `;

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <Head description={siteDescription} title={title} titleSuffix={siteTitle} author={siteAuthor} imageURL={sitePreviewImage.file.url} />
      <PageContainer>
        <PageContent>
          <TopNav
            contactLinkTitle={contactLinkTitle}
            teamLinkTitle={teamLinkTitle}
            servicesLinkTitle={servicesLinkTitle}
            caseStudiesLinkTitle={caseStudiesLinkTitle}
            logoURL={headerLogo.file.url}
            backgroundColor={landingPage ? theme.backgroundColor : "none"}
          />
          <div>
            {children}
          </div>
        </PageContent>
        <Footer
          hideContactBanner={hideContactFooterBanner}
          contactLinkTitle={contactLinkTitle}
          servicesLinkTitle={servicesLinkTitle}
          teamLinkTitle={teamLinkTitle}
          caseStudiesLinkTitle={caseStudiesLinkTitle}
          footerContactCTA={footerContactCTA}
          footerContactButtonText={footerContactButtonText}
          FooterCTA={FooterCTA}
        />
      </PageContainer>
    </ThemeProvider>
  );
};