import React, {useState} from "react";
import {Link, useStaticQuery, graphql} from "gatsby";
import styled from "@emotion/styled";
import DropDownIcon from "../../../images/down-arrow-black.png";
import {md, lg} from "../../../utils/breakpoints";
import {LinkContainer, Content} from "../../boxLink";
import { log } from "node:util";

const dropdownOptions = [
    "Blog",
    "Whitepapers",
];

const ContactContent = styled(Content)`
    font-size: 14px;
    padding: 5px 20px;

    @media (max-width: ${md}px) {
        padding: 20px 60px;
    }
`;

const ContactContainer = styled(LinkContainer)`

    &:hover {
        background-color: ${props => props.hovercolor ? props.hovercolor : props.primaryColor};

        > ${ContactContent} {
            border-color: #fff;
            color: #fff;
        }

    }
    
    @media (max-width: ${md}px) {
        margin: 20px auto;
        display: block;
    }
`;

const SVGButton = styled.button<any>`
    border: 0;
    cursor: pointer;
    background: url(${props => props.backgroundImg});
    background-repeat: no-repeat;
    outline: none;
`;

const CloseNavButton = styled(SVGButton)`
    position: absolute;
    top: 40px;
    right: 40px;
    width: 50px;
    height: 50px;
    opacity: .8;
    transition: .2s opacity ease-in-out;

    &:hover {
        opacity: 1;
    }
`;

const MobileMenuButton = styled(SVGButton)`
    width: 50px;
    height: 50px;
`;

const NavLinkList = styled.ul<any>`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    @media (max-width: ${md}px) {
        margin-top: -40px;
        flex-direction: column;
        justify-content: center;
    }
`;

const DropDownMenu = styled.div<any>`
    @media (min-width: ${md}px) {
        display: none;
        padding-top: 10px;
        position: absolute;
        z-index: 100;
        transform: translateX(-50px);
    }
`;

const NavLinkItem = styled.li<any>`
    display: ${props => props.hideDesktop ? "none" : "block"};
    margin-top: 20px;

    &.dropdown {
        // margin-bottom: -5px; - 
        margin-bottom: 4px;
        padding-bottom: 10px;
    }

    &.dropdown:hover {
        border-bottom: 1px solid ${props => props.theme.secondaryColor};
        // padding-bottom: 8px; // prevents appearance of movement on hover
    }

    &{navLinkItem}:not(:first-of-type) {
        margin-left: 60px;
    } 
    
    @media (max-width: 1220px) {
        &{navLinkItem}:not(:first-of-type) {
            margin-left: 40px;
        } 
    }
    
    @media (max-width: 875px) {
        &{navLinkItem}:not(:first-of-type) {
            margin-left: 30px;
        } 
    }
    
    @media (max-width: 830px) {
        &{navLinkItem}:not(:first-of-type) {
            margin-left: 20px;
        } 
    }

    &.dropdown {
        padding-right: 20px;
    }

    &.dropdown:hover ${DropDownMenu} {
        display: block;
    }
`;

const NavLink = styled(Link)<any>`
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${props => props.theme.primaryColor};
    display: block;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color .2s ease-in-out;
    
    &:hover {
        border-bottom-color: ${props => props.theme.secondaryColor};
    }

    &.dropdown-navitem:hover {
        border-bottom: 1px solid transparent;
    }

    @media (min-width: ${md}px) {
        &.dropdown-navitem:after {
            background-image: url(${DropDownIcon});
            background-size: 10px 10px;
            display: inline-block;
            width: 10px;
            height: 10px;
            content: "";
            position: absolute;
            bottom: 3px;
            right: -15px;
        }
    }

    @media (max-width: ${md}px) {
        color: #fff;
        font-size: 30px;
        padding: 10px 10px;
        margin-top: 10px;
        display: block;

        &.dropdown-navitem {
            text-align: center;
            margin-top: 0;
        }
    }
`;

const NavLinkListContainer = styled.div<any>`
    height: 100%;
    z-index: 10;
    transition: opacity .2s ease-in-out;

    ${CloseNavButton} {
        display: none;
    }
    
    &:before {
        content: '';
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url(${props => props.mobileBgImg});
        background-repeat: no-repeat;
        height: ${props => props.mobileBgImgHeight}px;
        width: ${props => props.mobileBgImgWidth}px;
    }

    @media (max-width: ${md}px) {
        background: ${props => props.theme.primaryColor};
        position: fixed;
        top: 0;
        left: ${props => props.isOpen ? 0 : "100%"};
        height: 100%;
        width: 100%;
        opacity: ${props => props.isOpen ? 100: 0};

        ${CloseNavButton} {
            display: block;
        }

        &:before {
            display: block;
        }
    }
`;

const NavListContent = styled.div<any>`
    height: 50px;

    ${MobileMenuButton} {
        display: none;
    }

    @media (max-width: ${md}px) {
        ${MobileMenuButton} {
            display: block;
        }
    }
`;

const DropDownLink = styled(NavLink)<any>`
    text-align: center;
    margin-bottom: 1px; // creates space between dropdown items
    left: 3px;

    &:first-child {
        margin-top: 10px;
    }
    

    &:hover {
        opacity: 70%;
    }

    @media (min-width: ${md}px) {
        display: block;
        padding: 16px 26px;
        background: ${props => props.theme.secondaryColor}; 
        color: #FFF;   
        cursor: pointer;
    }

    @media (max-width: ${md}px) {
        font-size: 24px;
        padding: 20px;
    }
`;

export interface INavListProps {
    servicesLinkTitle: string;
    caseStudiesLinkTitle: string;
    contactLinkTitle: string;
    teamLinkTitle: string;
}

export default function NavList(props: INavListProps): JSX.Element {
    const {
        servicesLinkTitle,
        caseStudiesLinkTitle,
        teamLinkTitle,
        contactLinkTitle,
    } = props;

    const data = useStaticQuery(graphql`
        query {
            waveFile: file(relativePath: { eq: "mobile-nav-wave.png" }) {
                childImageSharp {
                    fixed(width: 400, height: 50) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            closeBtnFile: file(relativePath: { eq: "close-button.svg" }) {
                publicURL   
            }
            menuBtnFile: file(relativePath: {eq: "menu-button.svg"}) {
                publicURL
            }
            contentfulLayout {
                footerContactButtonText
            }
        }
    `);

    const {
        footerContactButtonText
    } = data.contentfulLayout;

    const [isOpen, setOpen] = useState(false);

    return (
        <NavListContent>
            <MobileMenuButton type="button" backgroundImg={data.menuBtnFile.publicURL} onClick={() => setOpen(true)} />
            
            {/* The nav container that holds the nav 
            list and the mobile close button */}
            <NavLinkListContainer
                mobileBgImg={data.waveFile.childImageSharp.fixed.src}
                mobileBgImgHeight={50}
                mobileBgImgWidth={400}
                isOpen={isOpen}>
                <CloseNavButton type="button" backgroundImg={data.closeBtnFile.publicURL} onClick={() => setOpen(false)} />
                <NavLinkList>
                    <NavLinkItem>
                        <NavLink onClick={() => setOpen(false)} to="/services">
                            {servicesLinkTitle}
                        </NavLink>
                    </NavLinkItem>
                    <NavLinkItem>
                        <NavLink onClick={() => setOpen(false)} to="/case-studies">
                            {caseStudiesLinkTitle}
                        </NavLink>
                    </NavLinkItem>
                    <NavLinkItem>
                        <NavLink onClick={() => setOpen(false)} to="/team">
                            {teamLinkTitle}
                        </NavLink>
                    </NavLinkItem>
                    <NavLinkItem>
                        <NavLink onClick={() => setOpen(false)} to="/contact">
                            {contactLinkTitle}
                        </NavLink>
                    </NavLinkItem>
                    {/* START DROPDOWN  */}
                    <NavLinkItem className="dropdown">
                        <NavLink 
                            className="dropdown-navitem"
                            onClick={() => setOpen(false)}
                            to="blog"                           
                            > 
                            Resources
                        </NavLink>
                        <DropDownMenu className="dropdown-content">
                            {/* in future, if adding links, consider mapping through array of objects */}
                            <DropDownLink onClick={() => setOpen(false)} to="/blog" >
                                {dropdownOptions[0]}
                            </DropDownLink>
                            <DropDownLink onClick={() => setOpen(false)} to="/downloads" >
                                {dropdownOptions[1]}
                            </DropDownLink>
                        </DropDownMenu>
                    </NavLinkItem>
                    {/* END DROPDOWN */}
                    <NavLinkItem>
                        <ContactContainer to="/contact">
                            <ContactContent>
                                {footerContactButtonText}
                            </ContactContent>
                        </ContactContainer>
                    </NavLinkItem>
                </NavLinkList>
            </NavLinkListContainer>
        </NavListContent>

    );
}