/* global hbspt */
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import fetch from 'isomorphic-unfetch';
import Cookies from 'js-cookie';

import Container from "../container";
import { md } from "../../utils/breakpoints";
import { hubspotPortalId } from "../../utils/analytics";
import HubspotForm from '../HubspotForm';

const EmailContainer = styled.div<any>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;

    @media (max-width: ${md}px) {
        flex-direction: column;
    }
`;

const EmailText = styled.p<any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    width: 50%;
    margin-bottom: 0;

    @media(max-width: ${md}px) {
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }
`;

const EmailForm = styled.form<any>`
    width: 45%;
    height: 37px;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    
    @media(max-width: ${md}px) {
        width: 100%;
    }
`;

const EmailInput = styled.input<any>`
    width: 75%;
    border: none;
    font-family: ${props => props.theme.contentFontFamily};
    color: #1E3961;
    padding: 10px 7px 10px 23px;
`;

const EmailSignupBtn = styled.button<any>`
    width: 25%;
    margin-left: 10px;
    border: none;
    position: relative;
    font-family: ${props => props.theme.contentFontFamily};
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: #1E3961;
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
    cursor: pointer;
    background-color: #fff;

    ::before {
        background: none;
        border: 1px solid #1E3961;
        content: " ";
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        pointer-events: none;
        transition: border-color .2s ease-in-out;
    }

    &:hover {
        color: #fff;
        background-color: #1E3961;

        ::before {
            border-color: #fff;
        }
    }
`;

const Padding = styled.div<any>`
    padding-top: ${props => props.paddingTop}px;
`;

const SignupThanks = styled.div<any>`
    background: #fff;
    padding: 8px;
    text-align: center;
    margin-bottom: 16px;
`;

const ThanksWrapper = styled.div<any>`
    padding: 20px;
    border: 1px solid ${props => props.theme.primaryColor};
`;

const ThanksHeader = styled.h2<any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 10px;
`;

const ThanksBody = styled.p<any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 18px;
    margin-bottom: 0;
`;

const ThanksLink = styled(Link) <any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 18px;
    color: ${props => props.theme.primaryColor};
`;

export interface IEmailSignupProps {
}

export default function EmailSignup(props: IEmailSignupProps): JSX.Element {

  const [showForm, setShowForm] = useState(true);
  const [email, setEmail] = useState('');
  const [formSetup, setFormSetup] = useState(false);

  const formId = "42816e28-5283-4bc3-9264-6fa7b60f7384";

  const onFormSubmitted = () => {
    console.log("submitted");
    setShowForm(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${formId}`;

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      }
    }

    fetch(postUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(res => res.json())
      .then(() => {
        setShowForm(false);
      })
      .catch(err => {
        alert('Error sending form. Please try again, if the problem persists, contact us.')
      });
  }

  return (
    <Container>
      {showForm &&
        <EmailContainer>
          <EmailText>Sign up for our newsletter to receive high growth go-to-market insights, read interviews with marketing industry experts and be part of our community of visionaries.</EmailText>
          {/* <EmailForm onSubmit={handleSubmit}>
                  <EmailInput
                    type="email"
                    placeholder="Enter Email Address"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                  <EmailSignupBtn type="submit">
                    Sign Up
                  </EmailSignupBtn>
              </EmailForm> */}
          <HubspotForm newsletter formId={formId} onFormSubmitted={onFormSubmitted} />
        </EmailContainer>
      }
      {!showForm &&
        <React.Fragment>
          <Padding paddingTop="16" />
          <SignupThanks>
            <ThanksWrapper>
              <ThanksHeader>Thank you!</ThanksHeader>
              <ThanksBody>
                <ThanksLink to="/blog">Visit our Blog</ThanksLink> for more high-growth go-to-market strategy insights.
              </ThanksBody>
            </ThanksWrapper>
          </SignupThanks>
        </React.Fragment>
      }
    </Container>
  );
}