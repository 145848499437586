import React from "react"
import { Helmet } from "react-helmet"

export interface IHeadProps {
    description: string;
    title: string;
    titleSuffix: string;
    author: string;
    imageURL: string;
}

function Head(props: IHeadProps) {
    const { description, title, titleSuffix, author, imageURL } = props;

    return (
        <Helmet
        htmlAttributes={{
            lang: "en"
        }}
        title={title}
        titleTemplate={`%s | ${titleSuffix}`}
        meta={[
            {
                name: `description`,
                content: description,
            },
            {
                property: `og:title`,
                content: "Go-to-market strategy consultants.",
            },
            {
                property: `og:description`,
                content: `Offering go-to-market strategy for innovative tech companies. Our team of strategic marketers, researchers, and creatives help forward-thinking companies launch world-changing products.`,
            },
            {
                property: `og:type`,
                content: `website`,
            },
            {
                property: `og:locale`,
                content: `en_US`,
            },
            {
                property: `og:image`,
                content: imageURL
            },
            {
                property: `og:url`,
                content: `https://strategica.partners`
            },
            {
                property: `og:site_name`,
                content: `Strategica Partners`
            },
            {
                name: `twitter:card`,
                content: `summary_large_image`,
            },
            {
                name: `twitter:site`,
                content: "@strategica_mkt",
            },
            {
                name: `twitter:creator`,
                content: "@RebeccaSadwick",
            },
            {
                name: `twitter:title`,
                content: `Go-to-market strategy consultants.`,
            },
            {
                name: `twitter:description`,
                content: `Offering go-to-market strategy for innovative tech companies. Our team of strategic marketers, researchers, and creatives help forward-thinking companies launch world-changing products.`,
            },
            {
                name: `robots`,
                content: `index, follow`
            },
            {
                name: `keywords`,
                content: `Strategica, Strategica Partners, go to market strategy services, Consultants, Brand Development, Identity, User Experience, UX, User Research, user-centric, Insights, Marketing Strategy, Go-To-Market, Segmentation, Funnel Handoff, Key Performance Analysis, Data Analysis, Strategic Partnerships, Competitive Landscape, Product Roadmap, User Onboarding, User Retention, qualitative research, quantitative research`,
            },
        ]}
        >
            <link rel="canonical" href="https://strategica.partners" />
            <link itemprop="thumbnailUrl" href={imageURL} /> 
            <script type="application/ld+json">
                {`
                {
                    "@context": "https://schema.org",
                    "@graph": [
                        {
                            "@type": "Organization",
                            "@id": "https://strategica.partners/",
                            "sameAs": [
                                "https://www.linkedin.com/company/strategica-partners/",
                            ],
                            "name": "Strategica Partners",
                            "description": "Offering go-to-market strategy for innovative tech companies. Our team of strategic marketers, researchers, and creatives help forward-thinking companies launch world-changing products.",
                            "url": "https://strategica.partners",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "${imageURL}"
                            }
                        }
                    ]
                }
                `}
            </script>
        </Helmet>
    );
}

export default Head;