import React from "react";
import {Link} from "gatsby";
import styled from "@emotion/styled";

export const Content = styled.span<any>`
    font-size: 18px;
    text-transform: uppercase;
    display: inline-block;
    padding: ${props => props.big ? "20px 60px" : "10px 40px"};
    border: 1px solid ${props => props.borderColor ? props.borderColor : props.theme.primaryColor};
    color: ${props => props.textColor ? props.textColor : props.theme.primaryColor};
    transition: color .2s ease-in-out, border-color .2s ease-in-out;
`;

export const LinkContainer  = styled(Link)<any>`
    display: inline-block;
    background-color: #fff;
    padding: 5px;
    transition: background-color .2s ease-in-out;

    &:hover {
        background-color: ${props => props.hovercolor ? props.hovercolor : props.theme.primaryColor};

        > ${Content} {
            border-color: #fff;
            color: #fff;
        }
    }
`;

export interface IBoxLinkProps {
    children: any;
    to: string;
    borderColor?: string;
    textColor?: string;
    big?: boolean;
}

export default function BoxLink(props: IBoxLinkProps): JSX.Element {
    const {to, children, borderColor, textColor, big} = props;

    return (
        <LinkContainer hovercolor={borderColor} to={to}>
            <Content big={big} borderColor={borderColor} textColor={textColor}>{children}</Content>
        </LinkContainer>
    );
}