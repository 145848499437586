import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
    max-width: 76em;
    margin: 0px auto;
    padding: 0 20px;
`;

export default (props) => (
    <Container>
        {props.children}
    </Container>
);